
import Vue from 'vue'
import authApi from '@api/auth/apiMethod'
import { Form, message } from 'ant-design-vue';
let myform:any = Form

export default Vue.extend({
    name: 'LoginModal',
    props:{
        visible:{
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
        form: myform.createForm(this, { name: 'coordinated' }),
        loginLoading: false
      }
   },
   mounted() {
   },
   activated() {},
   methods: {
    closeModal(){
        this.$emit('closeModal')
    },
    handleOk(){
        console.log("ok")
    },
    onSubmit(){
        this.form.validateFields((err:any, values:any) => {
            if(!err){
                console.log('form: ', values);
                const that = this
                this.loginLoading = true
                authApi.login({
                    data: values,
                    success(res:any){
                        localStorage.setItem("token", res.token)
                        that.loginLoading = false
                        if(location.href.includes('reloaded')){
                            location.href = location.href.replace("#reloaded", '')
                        }else{
                            location.href = location.href + "#reloaded"
                        }
                        that.closeModal()
                        location.reload()
                    },
                    fail(error:any){
                        console.log(error.message)
                        message.error(error.message)
                        that.loginLoading = false
                    }
                })
            }
        });
    }
   }
})
