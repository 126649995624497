
    import Vue from 'vue'
    import {mapGetters} from 'vuex'
    import utils from '@corets/utils'
    import api from '@api/article/apiMethod'
    import aapi from '@api/auth/apiMethod'
    import {mapActions} from 'vuex'
    import LoginModal from "./LoginModal.vue"

    export default Vue.extend({
        name: 'PortalHeader',
        data () {
            return {
                currentTime: '时间获取中',
                greeting: '',
                curIdx: 0,
                navList: [],
                modalVisible: false,
            }
        },
        computed: {
            ...mapGetters([
                'userInfo',
                'comConfig'
            ]),
        },
        components:{
            LoginModal
        },
        methods: {
            ...mapActions([ 'changeUserInfo' ]),
            countTimer () {
                const self = this as any
                setInterval(() => {
                    self.currentTime = self.$moment(new Date()).format('YYYY-MM-DD dddd HH:mm:ss')
                }, 1000)
            },
            toLogin () {
                // 跳转到登录页
                // window.location.href = `/zmd-djy/auth?from=${encodeURIComponent(window.location.href)}`
                this.modalVisible = true
            },
            closeModal(){
                this.modalVisible = false
            },
            toMessage(){
                // this.$router.push({name: 'ActivityDetail', query:{article_id: item.articleId, channel_name: item.moduleTitle}})
                const url = this.$router.resolve({path: '/messageList'})
                window.open(url.href, '_blank')
            },
            toManage(){
                window.location.href = `/zmd-djy/manage?token=${localStorage.getItem('token')}`
            },
            articleListUrl (item: any, channelList: string[]) {
                if (item.link) {
                    return item.link
                } else {
                    return `/portal/articlelist?channel_id=${item.moduleId}&channel_name=${channelList.join('_')}`
                }
            },
            queryNav () {
                const self = this as any
                api.channelQuery({
                    data: {
                        filterConds: self.buildFilterConds({
                            parentModule: '#',
                            isNav: '1'
                        })
                    },
                    success (res: any) {
                        self.navList = self.formatChannel(res)
                    }
                })
            },
            formatChannel (channelList: any[]) {
                const self = this as any
                if (channelList) {
                    channelList.forEach((item: any, index: any) => {
                        const temChildren = self.formatChannel(item.moduleList)
                        item.children = temChildren || []
                        item.title = item.moduleTitle
                        item.key = item.moduleId
                        item.value = item.moduleId
                        item.scopedSlots = { title: 'custom' }
                    })
                    channelList = channelList.filter(item => item.isShow == 1)
                    return channelList
                } else {
                    return []
                }
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            },
            logOut () {
                const self = this as any
                aapi.logoutUser({
                    data: {},
                    success () {
                        self.$message.success('退出登陆成功', 2).then(() => {
                            // window.location.href = '/portal'
                            self.changeUserInfo({})
                            localStorage.setItem('cacheUserInfo', '')
                            location.reload();
                        })
                    },
                    fail (err: any) {
                        self.$message.error(err.errors)
                    }
                })
            }
        },
        created () {
            const self = this as any
            self.countTimer()
            self.greeting = utils.sayHiInfo()
            self.queryNav()
        }
    })
