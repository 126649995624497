import apiUrl from './apiUrl'
import utils from '@corets/utils'
import {ReqType, ResType} from '@corets/type'

const queryEventList = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.queryEventList
    const result = utils.sendReq(reqType)
    return result
}

const addEvent = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.addEvent
    const result = utils.sendReq(reqType)
    return result
}

const publicActivity = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.publicActivity
    const result = utils.sendReq(reqType)
    return result
}

const updateActivity = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.updateActivity
    const result = utils.sendReq(reqType)
    return result
}

const delActivity = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.delActivity
    const result = utils.sendReq(reqType)
    return result
}

const queryActivityResult = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.queryActivityResult
    const result = utils.sendReq(reqType)
    return result
}

const submitActivity = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.submitActivity
    const result = utils.sendReq(reqType)
    return result
}

const queryMyEventList = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.queryMyEventList
    const result = utils.sendReq(reqType)
    return result
}

const querySignIn = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.querySignIn
    const result = utils.sendReq(reqType)
    return result
}

const queryComment = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.queryComment
    const result = utils.sendReq(reqType)
    return result
}

const deleteComment = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.deleteComment
    const result = utils.sendReq(reqType)
    return result
}

const addComment = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.addComment
    const result = utils.sendReq(reqType)
    return result
}

const showEventIntegral = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.showEventIntegral
    const result = utils.sendReq(reqType)
    return result
}

const showEventIntegralDetail = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.showEventIntegralDetail
    const result = utils.sendReq(reqType)
    return result
}

export default {
    queryEventList,
    addEvent,
    publicActivity,
    updateActivity,
    delActivity,
    queryActivityResult,
    submitActivity,
    queryMyEventList,
    querySignIn,
    queryComment,
    deleteComment,
    addComment,
    showEventIntegral,
    showEventIntegralDetail
}
