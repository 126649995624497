import apiUrl from './apiUrl'
import utils from '@corets/utils'
import {ReqType, ResType} from '@corets/type'

const channelQuery = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.channelQuery
    const result = utils.sendReq(reqType)
    return result
}

const removeChannel = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.removeChannel
    const result = utils.sendReq(reqType)
    return result
}

const addChannel = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.addChannel
    const result = utils.sendReq(reqType)
    return result
}

const updateChannel = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.updateChannel
    const result = utils.sendReq(reqType)
    return result
}

const articleQuery = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.articleQuery
    const result = utils.sendReq(reqType)
    return result
}

const addArticle = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.addArticle
    const result = utils.sendReq(reqType)
    return result
}

const deleteArticle = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.deleteArticle
    const result = utils.sendReq(reqType)
    return result
}

const updateArticle = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.updateArticle
    const result = utils.sendReq(reqType)
    return result
}

const addView = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.addView
    const result = utils.sendReq(reqType)
    return result
}

const articleQueryNew = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.articleQueryNew
    const result = utils.sendReq(reqType)
    return result
}

const getExtraOrgList = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.getExtraOrgList
    const result = utils.sendReq(reqType)
    return result
}

export default {
    channelQuery,
    removeChannel,
    addChannel,
    updateChannel,
    articleQuery,
    addArticle,
    deleteArticle,
    updateArticle,
    addView,
    articleQueryNew,
    getExtraOrgList
}
