import comConfig from '@custom/config'

const apiHost = comConfig.apiHost

export default {
    channelQuery: `${apiHost}/webapi/portal/article/module/list`,
    removeChannel: `${apiHost}/webapi/admin/article/module/delete`,
    addChannel: `${apiHost}/webapi/admin/article/module/add`,
    updateChannel: `${apiHost}/webapi/admin/article/module/update`,
    articleQuery: `${apiHost}/webapi/portal/article/listPage`,
    addArticle: `${apiHost}/webapi/admin/article/add`,
    deleteArticle: `${apiHost}/webapi/admin/article/delete`,
    updateArticle: `${apiHost}/webapi/admin/article/update`,
    addView: `${apiHost}/webapi/portal/article/addReadCount`,
    articleQueryNew: `${apiHost}/webapi/portal/article/listPage`,
    getExtraOrgList: `${apiHost}/webapi/ExtraOrgList`,
}
