import comConfig from '@custom/config'

const apiHost = comConfig.apiHost

export default {
    queryEventList: `${apiHost}/webapi/admin/event/list`,
    addEvent: `${apiHost}/webapi/admin/event/add`,
    publicActivity: `${apiHost}/webapi/admin/event/publish`,
    updateActivity: `${apiHost}/webapi/admin/event/update`,
    delActivity: `${apiHost}/webapi/admin/event/delete`,
    queryActivityResult: `${apiHost}/webapi/admin/event/entryList`,
    submitActivity: `${apiHost}/webapi/admin/event/entry`,
    queryMyEventList: `${apiHost}/webapi/admin/event/getMyEvent`,
    querySignIn: `${apiHost}/webapi/admin/event/signInList`,
    queryComment: `${apiHost}/webapi/admin/comment/list`,
    deleteComment: `${apiHost}/webapi/admin/comment/delete`,
    addComment: `${apiHost}/webapi/admin/comment/add`,
    showEventIntegral: `${apiHost}/webapi/admin/integral/showEventIntegral`,
    showEventIntegralDetail: `${apiHost}/webapi/admin/integral/showEventIntegralDetail`
}
