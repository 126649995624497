import apiUrl from './apiUrl'
import utils from '@corets/utils'
import {ReqType, ResType} from '@corets/type'

const sendMessage = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.sendMessage
    const result = utils.sendReq(reqType)
    return result
}

const getMessageList = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.getMessageList
    const result = utils.sendReq(reqType)
    return result
}

const getMessageDetail = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = `${apiUrl.getMessageDetail}?messageUserId=${reqType.data.messageUserId}`
    const result = utils.sendReq(reqType)
    return result
}

const getMemberMessageDetai = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.getMemberMessageDetai
    const result = utils.sendReq(reqType)
    return result
}

export default {
    sendMessage,
    getMessageList,
    getMessageDetail,
    getMemberMessageDetai
}
