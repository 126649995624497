import comConfig from '@custom/config';

const apiHost = comConfig.apiHost

export default {
    sendMessage: `${apiHost}/webapi/admin/notify/sendMessageToAdmin`,
    getMessageList: `${apiHost}/webapi/admin/notify/MessageUserList`,
    getMessageDetail: `${apiHost}/webapi/admin/notify/MessageDetailList`,
    getMemberMessageDetai: `${apiHost}/webapi/admin/notify/memberMessageDetailList`,
    
}
