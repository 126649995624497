
import { UserOutlined } from '@ant-design/icons-vue';
import Vue from "vue";
import dict from "@custom/dict";
import api from '@api/chat/apiMethod'
import {mapGetters} from 'vuex'

export default Vue.extend({
    name: "ChatModal",
    props:{
        title:{
            type: String,
            default: '联系管理员'
        }
    },
    data() {
        return {
            dict,
            visible: false,
            chatList: [], // 聊天记录列表
            messageContent: '', // 发送内容
            sendLoading: false,
            userType: 2, // 当前用户类型
            senderId: "",
        };
    },
    computed: {
        ...mapGetters([
            'userInfo',
            'comConfig'
        ])
    },
    methods: {
        showModal(type: any, messageUserId: any, senderId: any) {  // type: 1-管理员 2-党员
            if(type == 1){
                this.userType = 1
                this.senderId = senderId
                this.getMessageDetail(messageUserId)
            }else{
                this.userType = 2
                this.getMemberMessageDetai()
            }
            this.visible = true;
        },
        // 设置聊天框自动滚动到底部
        autoScrollBottom(){
            this.$nextTick(() => {
                let scrollElem:any = this.$refs.chatHistory
                scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' })
            })
        },
        sendMessage(){
            const self = this as any
            if(!this.messageContent){
                return self.$message.warn('发送内容不能为空')
            }
            this.sendLoading = true
            api.sendMessage({
                data:{
                    senderId: this.userType==1?this.senderId: this.userInfo.userId,
                    messageContent: this.messageContent
                }, 
                success (res: any) {
                    self.sendLoading = false
                    self.messageContent = ""
                    self.$message.success('发送成功')
                    self.chatList = res.map((item: any)=> ({...item, isAdmin: item.senderId=='f568eb56b3764b06a04314da02af26ff'}))
                    self.autoScrollBottom()
                },
                error () {
                    self.sendLoading = false
                    self.$message.warn('发送失败，请稍后再试！')
                },
            })
        },
        getMessageDetail(messageUserId:any){
            const self = this as any
            api.getMessageDetail({
                data:{
                    messageUserId
                }, 
                success (res: any) {
                    self.chatList = res.map((item: any)=> ({...item, isAdmin: item.senderId=='f568eb56b3764b06a04314da02af26ff'}))
                    self.autoScrollBottom()
                },
            })
        },
        getMemberMessageDetai(){
            const self = this as any
            api.getMemberMessageDetai({
                success (res: any) {
                    self.chatList = res.map((item: any)=> ({...item, isAdmin: item.senderId=='f568eb56b3764b06a04314da02af26ff'}))
                    self.autoScrollBottom()
                },
            })
        }
    },
});
