
    import Vue from 'vue'
    import api from '@api/article/apiMethod'

    export default Vue.extend({
        name: 'NewsBlock',
        props: {
            title: {
                type: String
            },
            titleBg: {
                type: String,
                default: '1'
            },
            articleType: {
                type: String,
                default: 'channel'
            },
            channelId: {
                type: String
            },
            showMore: {
                type: Boolean,
                default: true
            },
        },
        data () {
            return {
                loading: true,
                newsList: []
            }
        },
        methods: {
            queryArticle() {
                const self = this as any
                if (self.articleType === 'hot') {
                    api.articleQuery({
                        data: {
                            filterConds: [],
                            orderBy: {
                                readCount: 'asc'
                            }
                        },
                        success(res: any) {
                            self.loading = false
                            if (res.data.length > 0) {
                                res.data.forEach((item: any) => {
                                    const diffDay = self.$moment(new Date()).diff(self.$moment(item.createdTime), 'days')
                                    if (diffDay <= 3) {
                                        item.isNew = true
                                    }
                                })
                                self.newsList = res.data.slice(0, 4)
                            }
                        },
                        fail(err: any) {
                            self.loading = false
                            self.$message.error(err.message)
                        }
                    })
                } else if (self.articleType === 'new') {
                    api.articleQuery({
                        data: {
                            filterConds: [],
                            orderBy: {
                                createdTime: 'desc'
                            }
                        },
                        success(res: any) {
                            self.loading = false
                            if (res.data.length > 0) {
                                res.data.forEach((item: any) => {
                                    const diffDay = self.$moment(new Date()).diff(self.$moment(item.createdTime), 'days')
                                    if (diffDay <= 3) {
                                        item.isNew = true
                                    }
                                })
                                self.newsList = res.data.slice(0, 4)
                            }
                        },
                        fail(err: any) {
                            self.loading = false
                            self.$message.error(err.message)
                        }
                    })
                } else {
                    api.articleQuery({
                        data: {
                            filterConds: self.buildFilterConds({
                                moduleId: self.channelId
                            })
                        },
                        success(res: any) {
                            self.loading = false
                            if (res.data.length > 0) {
                                res.data.forEach((item: any) => {
                                    const diffDay = self.$moment(new Date()).diff(self.$moment(item.createdTime), 'days')
                                    if (diffDay <= 3) {
                                        item.isNew = true
                                    }
                                })
                                self.newsList = res.data.slice(0, 4)
                            }
                        },
                        fail(err: any) {
                            self.loading = false
                            self.$message.error(err.message)
                        }
                    })
                }
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            }
        },
        created () {
            const self = this as any
            self.queryArticle()
        }
    })
